<template>
  <div class="row justify-center">
    <div
      v-for="item in items"
      :key="item.id"
      :class="col"
      style="min-width: 320px; max-width: 400px;"
    >
      <role-card
        :role="item"
        :arrow="arrow"
        :level="level"
        @submit="handleSubmit"
      />
    </div>

    <div v-if="!isLoading && items.length <= 0" class="text-caption text-center q-pt-md">
      {{ $t('No child roles') }}
    </div>

    <div
      v-if="isLoading"
      class="text-primary col-12 row fit justify-center items-center"
      style="min-height: 200px;"
    >
      <q-spinner
        color="light-blue-9"
        size="3rem"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleRow',
  emits: ['on-load'],
  props: {
    level: {
      type: Number,
      default () {
        return 1
      }
    },
    arrow: {
      type: Boolean,
      default () {
        return false
      }
    },
    filter: {
      type: Array,
      default () {
        return []
      }
    },
    direction: {
      type: String,
      default () {
        return 'row'
      }
    }
  },
  computed: {
    col () {
      if (window.innerWidth < 700) {
        return 'col-12 q-py-sm'
      }

      return this.direction === 'row'
        ? 'q-pa-sm'
        : 'col-12 q-py-sm'
    }
  },
  data () {
    return {
      items: [],
      itemsPage: 0,
      itemsTotalPages: 1,
      isLoading: false,
      internalFilter: [
        { type: 'eq', field: 'state', value: 'active' }
      ]
    }
  },
  mounted () {
    this.onRequest({ pagination: { per_page: 25, page: 1 } })
  },
  methods: {
    handleSubmit (item) {
      let isFounded = false

      this.items = this.items.map(x => {
        if (x.id === item.id) {
          isFounded = true
          return item
        }

        return x
      })

      if (!isFounded) {
        this.items.push(item)
      }
    },
    onRequest (params = { pagination: {} }) {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          ...this.internalFilter,
          ...this.filter
        ],
        ...params.pagination
      }

      this.isLoading = true
      return this.$service.userRoles.getAll(query)
        .then(({ items, page, totalPages }) => {
          this.itemsPage = page
          this.itemsTotalPages = totalPages

          if (page <= 1) {
            this.items = items
          } else {
            this.items = [
              ...this.items,
              ...items
            ]
          }

          this.$emit('on-load', this.items)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
